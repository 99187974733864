import {CargoItem} from "@/models/CargoItem";
import {Consignee} from "@/models/Consignee";
import {TowCompany} from "@/models/TowCompany";
import {Shipper} from "@/models/Shipper";

export class CargoLossInformation {
    lossEstimateAmount!: string;
    cargoPickupDate!: string;
    cargoStatePickedUpFrom!: string;
    cargoExpectedDeliveryDate!: string;
    cargoExpectedStateDeliveredTo!: string;
    wasThereDamageToCargo!: string;

    cargoList: Array<CargoItem> = new Array<CargoItem>();
    isConsigneeInfoAvailable: boolean = false;
    isShipperInfoAvailable: boolean = false;
    wasTowCompanyUsed: boolean = false;
    consignee: Consignee = new Consignee()
    shipper: Shipper  = new Shipper()
    towCompany: TowCompany = new TowCompany();
    isConsigneeAddressSameAsLossLocationAddress: boolean = false;
}
