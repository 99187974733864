<template>
  <div data-cy="header-component-vue"  v-if="checkPage()">
    <v-row class="header-row py-5">
      <v-col cols="3" class="header-div py-0">
        <img alt="company-logo-header" :src="require(`../../assets/${this.$store.state.siteBrand.companyLogoHeader}`)"  data-cy="header-logo" class="marginLeftOnSmallScreen logo float-left">
      </v-col>
      <v-col cols="6" class="py-0 claim-start-now-img">
        <img data-cy="claim-start-now-logo" style="max-width: 70%"  src="../../assets/ClaimStartNow_WM.png" alt="StartNowImage">
      </v-col>
      <v-col cols="2" class="user-name-align" data-cy="logged-in-user-name-col" v-if="this.$store.state.loggedInUser.isAuthenticated">
        <div data-cy="logged-in-user-name-desktop" v-if="this.$route.name != 'home' && $vuetify.breakpoint.mdAndUp" class="user-name">
         <div data-cy="faq-link" class="subtitle-2 faq-link" @click="showFaqModalOnClick">FAQ</div> <span v-if="this.$store.state.loggedInUser.lastName">|</span>
          <v-icon class="user-icon ml-6" data-cy="user-icon">mdi-account-circle-outline</v-icon>{{this.$store.state.loggedInUser.firstName + " " + this.$store.state.loggedInUser.lastName}}</div>
        <div class="user-name" data-cy="logged-in-user-name-mobile" v-else><span class="user-name-mobile">{{ this.$store.state.displayUserInitials }}</span></div>
      </v-col>
      <v-col cols="3" v-if="!this.$store.state.loggedInUser.isAuthenticated">
        <div data-cy="faq-link" class="subtitle-2 unauth-faq-link" @click="showFaqModalOnClick">FAQ</div>
      </v-col>
    </v-row>
    <v-row>
      <v-divider v-if="this.$route.name !== 'access-agreement'"></v-divider>
    </v-row>
        <FaqComponent @clicked="onFaqClose" v-if="this.showFaqModal" data-cy="faq-component"/>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import FaqComponent from './faq/FaqComponent.vue';


@Component({
  components: {FaqComponent}
})
export default class Header extends Vue {

  showFaqModal = false;

  checkPage() {
    let needsHeader = false;
    if (this.$route.name !== null && this.$route.name !== 'home' && this.$route.name !== 'landing-page') {
      needsHeader = true;
    }
    return needsHeader;
  }

  showFaqModalOnClick(){
    this.showFaqModal = !this.showFaqModal
  }

  onFaqClose(reset){
    this.showFaqModal = reset;
}

}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
div.v-application div.header-row {
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding: 4px 0 0 40px;
    max-height: 80px;
  }
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding: 40px 0 40px 40px;
    max-height: 180px;
  }
}

.logo{
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    max-height: 48px;
    width: auto;
  }
}

.user-name-align{
  display: block;
  margin-block: auto;
}

.faq-link{
  color: white;
  cursor: pointer;
  font-weight: bold;
  padding-right: 24px;
}

.faq-nav-icon {
  padding-bottom: 2px;
  color: white;
}

.unauth-faq-link{
  color: white;
  cursor: pointer;
  font-weight: bold;
  flex: auto;
  text-align: right;
  padding: 1em 6em 0 0
}

</style>
