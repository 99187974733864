<template>
  <v-row class="pt-2" data-cy="mobile-stepper"
         v-if="this.$route.name == 'getting-started'
            || this.$route.name == 'contact-information'
            || this.$route.name == 'loss-information'
            || this.$route.name == 'accident-information'
            || this.$route.name == 'property-loss-information'
            || this.$route.name == 'cargo-loss-information'
            || this.$route.name == 'liability-loss-information'
            || this.$route.name == 'other-party'
            || this.$route.name == 'additional-contacts'
            || this.$route.name == 'additional-information'
            || this.$route.name == 'review-and-submit'">
    <v-col class="pa-0" cols="12">
      <v-card
          class="mx-auto"
          color="#fff"
          height="85px">
        <v-list-item two-line class="pl-9 pr-9 mt-n2">
          <v-list-item-avatar
              size="60"
              color="transparent"
              class="mt-3">
            <v-progress-circular
                data-cy="mobile-stepper-progress-circular"
                :rotate="-90"
                :size="60"
                :width="5"
                :value="$route.name === 'getting-started' ? 14 :
                        $route.name === 'contact-information' ? 28 :
                        $route.name === 'loss-information' ? 42 :
                        $route.name === 'accident-information' ? 57 :
                        $route.name === 'cargo-loss-information' ?  57 :
                        $route.name === 'liability-loss-information' ? 57 :
                        $route.name === 'property-loss-information' ? 57 :
                        $route.name === 'other-party' ? 63 :
                        $route.name === 'additional-contacts' ? 72 :
                        $route.name === 'additional-information' ? 86 :
                        $route.name === 'review-and-submit' ? 100 : 0"
                color="#00ac5d">
              <span class="stepper-numbers" data-cy="mobile-stepper-page-number">
                {{$route.name === 'getting-started' ? '1 of ' +  this.$store.state.totalNumberOfPages :
                  $route.name === 'contact-information' ? '2 of ' + this.$store.state.totalNumberOfPages :
                  $route.name === 'loss-information' ? '3 of ' + this.$store.state.totalNumberOfPages :
                  $route.name === 'accident-information' ? '4 of ' + this.$store.state.totalNumberOfPages:
                  $route.name === 'cargo-loss-information' ? '4 of ' + this.$store.state.totalNumberOfPages :
                  $route.name === 'liability-loss-information' ? '4 of ' + this.$store.state.totalNumberOfPages :
                  $route.name === 'property-loss-information' ? '4 of ' + this.$store.state.totalNumberOfPages :
                  $route.name === 'other-party' ? '5 of ' + this.$store.state.totalNumberOfPages :
                  $route.name === 'additional-contacts' ? this.$store.state.additionalContactsStepNumber + ' of ' + this.$store.state.totalNumberOfPages :
                  $route.name === 'additional-information' ? this.$store.state.additionalInformationStepNumber + ' of ' + this.$store.state.totalNumberOfPages:
                  $route.name === 'review-and-submit' ? this.$store.state.reviewStepNumber + ' of ' + this.$store.state.totalNumberOfPages : ''
                }}
              </span>
            </v-progress-circular>
          </v-list-item-avatar>

          <v-list-item-content class="pa-0">
            <v-list-item-title class="stepper-title" data-cy="mobile-stepper-current-page">
              {{$route.name === 'getting-started' ? 'Getting Started' :
                $route.name === 'contact-information' ? 'Customer Information' :
                $route.name === 'loss-information' ? 'Loss Information' :
                $route.name === 'accident-information' ? 'Accident Information' :
                $route.name === 'property-loss-information' ? 'Property Information' :
                $route.name === 'cargo-loss-information' ? 'Cargo Information' :
                $route.name === 'liability-loss-information' ? 'Liability Information' :
                $route.name === 'other-party' ? 'Other Parties' :
                $route.name === 'additional-contacts' ? 'Additional Contacts' :
                $route.name === 'additional-information' ? 'Additional Information' :
                $route.name === 'review-and-submit' ? 'Review & Submit' : '' }}
            </v-list-item-title>
            <v-list-item-subtitle class="stepper-subtitle" data-cy="mobile-stepper-next-page">
              {{$route.name === 'getting-started' ? 'Next: Customer Information' :
                $route.name === 'contact-information' ? 'Next: Loss Information' :
                $route.name === 'loss-information' ? 'Next: ' + this.getLossType() + ' Loss Type' :
                $route.name === 'accident-information' ? 'Next: Other Parties' :
                $route.name === 'property-loss-information' ? 'Next: Additional Contacts' :
                $route.name === 'cargo-loss-information' ? 'Next: Additional Contacts' :
                $route.name === 'liability-loss-information' ? 'Next: Additional Contacts' :
                $route.name === 'other-party' ? 'Next: Additional Contacts' :
                $route.name === 'additional-contacts' ? 'Next: Additional Information' :
                $route.name === 'additional-information' ? 'Next: Review & Submit' :
                $route.name === 'review-and-submit' ? '' : '' }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Component from "vue-class-component";
import {Vue} from "vue-property-decorator";

@Component
export default class MobileStepper extends Vue {

  getLossType(){
    if(this.$store.state.submission.lossInformation.lossType != null ||
        this.$store.state.submission.lossInformation.lossType != undefined){
      return this.$store.state.submission.lossInformation.lossType;
    }

    return "";
  }

}

</script>

<style lang="scss" scoped>
.stepper-title {
  font-family: jaf-bernina-sans-comp, sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #036;
}

.stepper-numbers {
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
}

.stepper-subtitle {
  font-family: proxima-nova;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #00274e !important;
}
</style>