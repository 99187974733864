<template>
  <div data-cy="footer-component-vue" style="background-color: #ccd4dc; font-size: 14px" class="centerElementsWhenSmAndDown proximaNovaFont">
    <v-row>
      <v-col cols="12" md="3">
        <img alt="company-logo-footer" :src="require(`../../assets/${this.$store.state.siteBrand.companyLogoFooter}`)" width="200px"
             style="margin-top: 15px; margin-left: 15%" class="marginLeftOnSmallScreen" data-cy="logo-footer">
      </v-col>

      <v-col cols="12" md="3" style="color: #335271">
        <p class="berninaSansFont" style="font-size: 18px" data-cy="about-us">ABOUT US</p>
        <div>
          <label><a href="https://www.greatamericaninsurancegroup.com/contact/legal-disclosures"
                    target="_blank" data-cy="legal-disclosures">
            <span> Legal Disclosures / Terms and Conditions</span> </a></label>
        </div>
        <div>
          <label><a
              href="https://www.greatamericaninsurancegroup.com/docs/default-source/default-document-library/gaig-pcservice-marks.pdf"
              target="_blank" data-cy="service-marks"><span>Service Marks</span></a></label>
        </div>
        <div>
          <label><a href="https://www.greatamericaninsurancegroup.com/contact/privacy" target="_blank"
                    data-cy="privacy"><span>Privacy</span></a></label>
        </div>
        <div>
          <label><a href="https://www.greatamericaninsurancegroup.com/contact/privacy/cookie-policy"
                    target="_blank" data-cy="cookie-policy"><span> Cookie Policy</span></a></label>
        </div>
        <div>
          <label><a href="https://www.greatamericaninsurancegroup.com/contact/accessibility" target="_blank"
                    data-cy="accessibility"><span>Accessibility</span></a></label>
        </div>
      </v-col>

      <v-col cols="12" md="3" data-cy="address">
        <p data-cy="address-title" style="color: #335271 ; font-size: 18px" class="berninaSansFont">ADDRESS</p>
        <div data-cy="address-line1">{{this.$store.state.siteBrand.addressLine1}}</div>
        <div data-cy="address-line2">{{this.$store.state.siteBrand.addressLine2}}</div>
        <div data-cy="address-line3">{{ this.$store.state.siteBrand.addressLine3 }}</div>
      </v-col>

      <v-col cols="12" md="3" data-cy="contact-us">
        <p data-cy="contact-us-title" style="color: #335271 ; font-size: 18px" class="berninaSansFont">CONTACT US</p>
        <div data-cy="eight-hundred-number">Phone: {{this.$store.state.siteBrand.eightHundredNumber}}</div>
        <div data-cy="direct-number">Phone: {{this.$store.state.siteBrand.phoneNumber}}</div>
        <div v-if="this.$store.state.siteBrand.faxNumber" data-cy="fax-number">Fax: {{this.$store.state.siteBrand.faxNumber}}</div>

        <div>
          <label>
            <span data-cy="contact-email-label">Email: </span>
            <a :href="`mailto:${this.$store.state.siteBrand.contactEmail}`" target="_blank" data-cy="contact-email"><span>{{this.$store.state.siteBrand.contactEmail}}</span></a>
          </label>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';

@Component
export default class Footer extends Vue {
}
</script>

<style scoped lang="scss">
@media screen and (max-width: 960px) {
  .marginLeftOnSmallScreen {
    margin-left: 0 !important;
  }
  .centerElementsWhenSmAndDown {
    text-align: center;
  }
}
</style>
