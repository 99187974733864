import {Driver} from "@/models/Driver";
import {VehicleInformation} from "@/models/VehicleInformation";
import {OtherPassenger} from "@/models/OtherPassenger";

export class Party {
    driver: Driver = new Driver();
    vehicle: VehicleInformation = new VehicleInformation();
    otherPassengers: Array<OtherPassenger> = new Array<OtherPassenger>();
    hasDriverInfo: boolean = false;
}
