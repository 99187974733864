export enum RelationToInsured {
    agent = "Agent/Broker",
    attorney = "Attorney",
    claimant = "Claimant",
    claimantcarrierExt = "Claimant Carrier",
    domesticpartner = "Domestic Partner",
    driverExt = "Driver",
    employee = "Employee",
    employer = "Employer",
    friend = "Friend",
    hrrep = "HR Representative",
    injuredworker = "Injured Worker",
    medicalprovider = "Medical Provider",
    nursecasetrgExt = "Nurse Care Triage",
    relative = "Relative",
    rentalcompanyExt = "Rental Company",
    repairshop = "Repair Shop",
    riskmanager = "Risk Manager",
    self = "Self/Insured",
    spouse = "Spouse",
    stateentityExt = "State Entity",
    supervisor = "Supervisor",
    trainer = "Trainer",
    other = "Other"
}
