import {
  axiosInstance,
  axiosInstanceWithLongerTimeout,
} from "@/utils/axios-instance";
import { FnolSubmission } from "@/models/FnolSubmission";
import { InternalAdminData } from "@/models/InternalAdminData";
import { User } from "@/models/User";
import { PolicySearchResponse } from "@/models/PolicyData/PolicySearchResponse";
import { PolicySearchRequest } from "@/models/PolicyData/PolicySearchRequest";
import { PolicyRetrieveRequest } from "@/models/PolicyData/PolicyRetrieveRequest";
import { RiskInformation } from "@/models/PolicyData/RiskInformation";
import { FdwInquiryRequest } from "@/models/PolicyData/FdwInquiryRequest";
import { FdwInquiryResponse } from "@/models/PolicyData/FdwInquiryResponse";
import { PolicyData } from "@/models/PolicyData";
import { AplLoss } from "@/models/Loss/AplLoss";

export class FnolApi {
  async getKey(): Promise<string> {
    const response = await axiosInstance.request<string>({
      method: "post",
      url: "/recaptcha/key",
      responseType: "json",
    });
    return response.data;
  }

  async isTokenValid(token: string): Promise<boolean> {
    const response = await axiosInstance.request<boolean>({
      method: "get",
      url: "/recaptcha/verify/" + token,
      responseType: "json",
    });
    return response.data;
  }

  async getSubmission(id: string): Promise<FnolSubmission> {
    const response = await axiosInstance.request<FnolSubmission>({
      method: "post",
      url: "/fnol/submission/" + id,
      responseType: "json",
    });

    return response.data;
  }

  async createSubmission(
    fnolSubmission: FnolSubmission
  ): Promise<FnolSubmission> {
    const response = await axiosInstance.request<FnolSubmission>({
      method: "post",
      url: "/fnol/create",
      data: fnolSubmission,
      responseType: "json",
    });
    return response.data;
  }

  async updateSubmission(
    fnolSubmission: FnolSubmission
  ): Promise<FnolSubmission> {
    const response = await axiosInstance.request<FnolSubmission>({
      method: "put",
      url: "/fnol/submission/update",
      data: fnolSubmission,
      responseType: "json",
    });
    return response.data;
  }

  async isInternal(): Promise<boolean> {
    const response = await axiosInstance.request<boolean>({
      method: "post",
      url: "/isInternal",
      responseType: "json",
    });
    return response.data;
  }

  async isEnvDevOrLocal(): Promise<boolean> {
    const response = await axiosInstance.request<boolean>({
      method: "post",
      url: "/isEnvDevOrLocal",
      responseType: "json",
    });
    return response.data;
  }

  async getInternalAdminData(): Promise<InternalAdminData> {
    const response = await axiosInstance.request<InternalAdminData>({
      method: "get",
      url: "/fnol/info/internaladmindata",
      responseType: "json",
    });

    return response.data;
  }

  async cancelSubmission(
    fnolSubmission: FnolSubmission
  ): Promise<FnolSubmission> {
    const response = await axiosInstance.request<FnolSubmission>({
      method: "put",
      url: "/fnol/submission/cancel",
      data: fnolSubmission,
      responseType: "json",
    });
    return response.data;
  }

  async getUser(): Promise<User> {
    const response = await axiosInstance.request<User>({
      method: "get",
      url: "/getUser",
      responseType: "json",
    });
    return response.data;
  }

  async getBusinessUnit(
    request: FdwInquiryRequest
  ): Promise<FdwInquiryResponse> {
    const response = await axiosInstance.request<FdwInquiryResponse>({
      method: "post",
      url: "/policy/fnol/getBusinessUnitByPolicyNumber",
      responseType: "json",
      data: request,
    });

    return response.data;
  }

  async getAgentPolicies(
    request: FdwInquiryRequest
  ): Promise<FdwInquiryResponse> {

    const response =
      await axiosInstanceWithLongerTimeout.request<FdwInquiryResponse>({
        method: "post",
        url: "/policy/fnol/agent/getPoliciesByProducerCodes",
        responseType: "json",
        data: request,
      });

    return response.data;
  }

  async getInsuredPolicies(email: string): Promise<PolicyData[]> {
    const response = await axiosInstanceWithLongerTimeout.request<PolicyData[]>(
      {
        method: "post",
        url: "/policy/fnol/insured/getPoliciesByEmail/" + email,
        responseType: "json",
      }
    );

    return response.data;
  }

  async doPolicySearch(
    request: PolicySearchRequest
  ): Promise<PolicySearchResponse> {
    const response = await axiosInstance.request<PolicySearchResponse>({
      method: "post",
      url: "/policy/fnol/policySearch",
      responseType: "json",
      data: request,
    });
    return response.data;
  }

  async doPolicyRetrieve(
    request: PolicyRetrieveRequest
  ): Promise<RiskInformation> {
    const response =
      await axiosInstanceWithLongerTimeout.request<RiskInformation>({
        method: "post",
        url: "/policy/fnol/policyRetrieve",
        responseType: "json",
        data: request,
      });
    return response.data;
  }

  async getLossCauseList(aplLoss: AplLoss): Promise<Array<AplLoss>> {
    const response = await axiosInstance.request<Array<AplLoss>>({
      method: "post",
      url: "/fnol/info/lossCause",
      responseType: "json",
      data: aplLoss,
    });
    return response.data;
  }

  async getLossCauseDetailList(aplLoss: AplLoss): Promise<Array<AplLoss>> {
    const response = await axiosInstance.request<Array<AplLoss>>({
      method: "post",
      url: "/fnol/info/lossCauseDetail",
      responseType: "json",
      data: aplLoss,
    });
    return response.data;
  }
}
