<template>
  <v-snackbar data-cy="validation-snackbar" v-model="$store.state.showValidationSnackbar" :top="true" multi-line color="#8E0122" :timeout="6000" elevation="24" >
    {{this.$route.name == "review-and-submit"? reviewAndSubmitText : snackBarText}}
  </v-snackbar>
</template>

<script lang="ts">

import {Component, Vue} from 'vue-property-decorator';

@Component
export default class ValidationSnackbar extends Vue{
snackBarText: string = "Please review and correct errors on the page before continuing."
reviewAndSubmitText:string = "We are missing some information needed to submit this claim"

}
</script>

<style scoped>

</style>