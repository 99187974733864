import {VehicleInformation} from "@/models/VehicleInformation";
import {Driver} from "@/models/Driver";
import {Passenger} from "@/models/Passenger";

export class AccidentInformation {
  vehicleInformation: VehicleInformation = new VehicleInformation()
  driver: Driver;
  passengers: Array<Passenger> = new Array<Passenger>();

  hasDriverInfo: boolean;
}