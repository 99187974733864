import {PsarAddress} from "@/models/PolicyData/PsarAddress";

export class PolicyDetails {
    insuredName!: string;
    policyModule!: string;
    policyNumber!: string;
    policySymbol!: string;
    policyTermKey!: string;
    policyVersion!: string;
    insuredAddress!: PsarAddress;
    effectiveDate!: string;
    expirationDate!: string;
    policySourceSystem!: string;
    policyBusinessUnit!: string;
}
