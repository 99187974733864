import {PropertyOwner} from "@/models/PropertyOwner";

export class PropertyLossInformation{
    propertyOwnerSameAsInsured: boolean = false;
    propertyOwnerSameAsPrimaryContact: boolean = false;
    doesHavePropertyOwnerInfo: boolean = false;

    damagedPropertyDesc: string;
    doesBusinessNeedToClose: boolean;
    reasonForClosure: string;
    estimateOfLoss: string;

    propertyOwner: PropertyOwner;
}