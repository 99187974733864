import axios, { AxiosInstance } from "axios";

let CSRF_TOKEN: string;

if (document.cookie.match(new RegExp(`XSRF-TOKEN=([^;]+)`)) != null) {
    CSRF_TOKEN = document.cookie.match(new RegExp(`XSRF-TOKEN=([^;]+)`))[1];

} else {
    CSRF_TOKEN = ""
}

// Specifying the window.location so api calls are not affected by aliasing done in the router
const axiosInstance: AxiosInstance = axios.create({
    baseURL: window.location.origin + '/api',
    timeout: 10000,
    headers: {
        "X-XSRF-TOKEN": CSRF_TOKEN,
        'Content-Type': 'application/json;charset=UTF-8',
    }
});

// Upping timeout to 1 minute due to PSAR slowness. Hopefully this gets resolved and can be lowered
// Specifying the window.location so api calls are not affected by aliasing done in the router
const axiosInstanceWithLongerTimeout: AxiosInstance = axios.create({
    baseURL: window.location.origin + '/api',
    timeout: 60000,
    headers: {
        "X-XSRF-TOKEN": CSRF_TOKEN,
        'Content-Type': 'application/json;charset=UTF-8',
    }
})

export {
    axiosInstance,
    axiosInstanceWithLongerTimeout
}