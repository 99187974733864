export enum UsStates {
  AK = "Alaska",
  AL = "Alabama",
  AR = "Arkansas",
  AZ = "Arizona",
  CA = "California",
  CO = "Colorado",
  CT = "Connecticut",
  DC = "District Of Columbia",
  DE = "Delaware",
  FL = "Florida",
  GA = "Georgia",
  GU = "Guam",
  HI = "Hawaii",
  IA = "Iowa",
  ID = "Idaho",
  IL = "Illinois",
  IN = "Indiana",
  KS = "Kansas",
  KY = "Kentucky",
  LA = "Louisiana",
  MA = "Massachusetts",
  MD = "Maryland",
  ME = "Maine",
  MI = "Michigan",
  MN = "Minnesota",
  MO = "Missouri",
  MS = "Mississippi",
  MT = "Montana",
  NC = "North Carolina",
  ND = "North Dakota",
  NE = "Nebraska",
  NH = "New Hampshire",
  NJ = "New Jersey",
  NM = "New Mexico",
  NV = "Nevada",
  NY = "New York",
  OH = "Ohio",
  OK = "Oklahoma",
  OR = "Oregon",
  PA = "Pennsylvania",
  PR = "Puerto Rico",
  RI = "Rhode Island",
  SC = "South Carolina",
  SD = "South Dakota",
  TN = "Tennessee",
  TX = "Texas",
  UT = "Utah",
  VA = "Virginia",
  VT = "Vermont",
  WA = "Washington",
  WI = "Wisconsin",
  WV = "West Virginia",
  WY = "Wyoming"
}

export enum CaProvinces {
  AB = "Alberta",
  BC = "British Columbia",
  MB = "Manitoba",
  NB = "New Brunswick",
  NL = "Newfoundland and Labrador",
  NT = "Northwest Territories",
  NS = "Nova Scotia",
  NU = "Nunavut",
  ON = "Ontario",
  PE = "Prince Edward Island",
  QC = "Quebec",
  SK = "Saskatchewan",
  YT = "Yukon"
}

export enum CombinedStates {
  AB = "Alberta",
  AK = "Alaska",
  AL = "Alabama",
  AR = "Arkansas",
  AZ = "Arizona",
  BC = "British Columbia",
  CA = "California",
  CO = "Colorado",
  CT = "Connecticut",
  DC = "District Of Columbia",
  DE = "Delaware",
  FL = "Florida",
  GA = "Georgia",
  GU = "Guam",
  HI = "Hawaii",
  IA = "Iowa",
  ID = "Idaho",
  IL = "Illinois",
  IN = "Indiana",
  KS = "Kansas",
  KY = "Kentucky",
  LA = "Louisiana",
  MA = "Massachusetts",
  MB = "Manitoba",
  MD = "Maryland",
  ME = "Maine",
  MI = "Michigan",
  MN = "Minnesota",
  MO = "Missouri",
  MS = "Mississippi",
  MT = "Montana",
  NB = "New Brunswick",
  NC = "North Carolina",
  ND = "North Dakota",
  NE = "Nebraska",
  NH = "New Hampshire",
  NJ = "New Jersey",
  NL = "Newfoundland and Labrador",
  NM = "New Mexico",
  NS = "Nova Scotia",
  NT = "Northwest Territories",
  NU = "Nunavut",
  NV = "Nevada",
  NY = "New York",
  OH = "Ohio",
  OK = "Oklahoma",
  ON = "Ontario",
  OR = "Oregon",
  PA = "Pennsylvania",
  PE = "Prince Edward Island",
  PR = "Puerto Rico",
  QC = "Quebec",
  RI = "Rhode Island",
  SC = "South Carolina",
  SD = "South Dakota",
  SK = "Saskatchewan",
  TN = "Tennessee",
  TX = "Texas",
  UT = "Utah",
  VA = "Virginia",
  VT = "Vermont",
  WA = "Washington",
  WI = "Wisconsin",
  WV = "West Virginia",
  WY = "Wyoming",
  YT = "Yukon",
}