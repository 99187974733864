import {CustomerServiceRep} from "../../src/models/CustomerServiceRep";
import UploadedFileStatus from "@/documentUpload/UploadedFileStatus";

export class AdditionalInformation {
  additionalCopies!: string;
  translatorNeeded!: boolean;
  preferredLanguage!: string;
  additionalNotes!: string;
  customerServiceRep!: CustomerServiceRep;
  uploadedFileStatuses: UploadedFileStatus[] = [];
}
