import {Contact} from "@/models/Contact";

export class Driver extends Contact {
  insuredEmployee!: boolean;
  sustainInjury!: string;
  injury!: string;
  partyDamagedProperty!: boolean;
  isSameAsPrimaryContact: boolean = false;
  isAddressSameAsInsured: boolean = false;
}
