export class Branding {
  rootRoutes: Array<string> = ["/", "/mcg"]

  brandName: string = "APL"
  companyLogoHeader: string = "gaig_red_white.svg"
  companyLogoFooter: string = "gaig_navy.svg"
  phoneNumber: string = "513-369-5000"
  eightHundredNumber: string = "800-545-4269"
  faxNumber: string = null
  addressLine1: string = "Great American Insurance Group"
  addressLine2: string = "301 East Fourth Street"
  addressLine3: string = "Cincinnati, Ohio 45202"
  contactEmail: string = "contactus@gaig.com"
  contactInfo: string = "<div>\n" +
      "<p data-cy='contact-us-info-span'>1-844-GAIG NOW / 1-844-424-4669.</p>\n" +
      "</div>"
  policySymbolMobileHelper :string = "<span data-cy='generic-mobile-symbol-helper' class='explain-text'>&nbsp; &nbsp;( ex. <span class='mark-explain-text'>AAA</span> 1234567 )"
  policyNumberMobileHelper :string = "<span data-cy='generic-mobile-number-helper' class='explain-text'>&nbsp; &nbsp;( ex. AAA<span class='mark-explain-text'>1234567</span> )"
  policySymbolDesktopHelper: string ="<v-subheader data-cy='generic-desktop-symbol-helper' class='explain-text'>( ex. <span class='mark-explain-text'>AAA</span> 1234567 )</v-subheader>"
  policyNumberDesktopHelper: string = "<v-subheader data-cy='generic-desktop-number-helper' class='explain-text'>( ex. AAA<span class='mark-explain-text'>1234567</span> )</v-subheader>"
  policyNumberHelperText: string = null;
  autoPolicyExamples: string = '';
  cargoPolicyExamples: string = '';
  propertyPolicyExamples: string = '';
  liabilityPolicyExamples: string = '';
  confirmationNumber: string = "1-844-424-4669"
  confirmationEmail: string = "claims@gaig.com"
  cssFile: string = null
  sitePath: string = '';
  reportingProcessContacted: string = " within 1 business day."
  confirmationContacted: string = " by the end of the next business day."


  createBranding(host: string) {
    switch (true) {
      case (host.includes("mcg")):
        return this.createMcgBranding();
      default:
        return this;
    }
  }

  createMcgBranding() {
    this.brandName = "MCG"
    this.companyLogoHeader = "mcg_white.png"
    this.companyLogoFooter = "mcg_red_blue.png"
    this.phoneNumber = "(918) 587-7221"
    this.faxNumber = "(918) 586-0840"
    this.eightHundredNumber = "(800) 722-4994"
    this.addressLine1 = "Mid-Continent Group Claims"
    this.addressLine2 = "1437 S Boulder, Suite 200"
    this.addressLine3 = "Tulsa OK 74119"
    this.contactEmail = "claims@mcg-ins.com"
    this.contactInfo = "<div>\n" +
        "<span data-cy='contact-us-info-span'>(800) 722-4994 or Email: </span>\n" +
        "<a href='mailto:claims@mcg-ins.com' data-cy='contact-us-email-link'>\n" +
        "<span data-cy='contact-us-email-span'>claims@mcg-ins.com.</span>\n" +
        "</a>\n" +
        "</div>"
    this.policySymbolMobileHelper = `<img data-cy='mcg-mobile-symbol-helper' alt='policy-symbol-helper'  src="/MCG_Mobile_Policy_Symbol_Helper.png" />`
    this.policyNumberMobileHelper = `<img data-cy='mcg-mobile-number-helper' alt='policy-number-helper' src="/MCG_Mobile_Policy_Number_Helper.png" />`
    this.policySymbolDesktopHelper = `<img data-cy='mcg-desktop-symbol-helper' alt='policy-symbol-helper' src="/MCG_Desktop_Policy_Symbol_Helper.png" />`
    this.policyNumberDesktopHelper = `<img data-cy='mcg-desktop-number-helper' alt='policy-number-helper' src="/MCG_Desktop_Policy_Number_Helper.png" />`
    this.policyNumberHelperText = `(Please omit any leading 0)`;
    this.autoPolicyExamples = ' [Policy prefixes: CA]'
    this.cargoPolicyExamples = ' [CIM Cargo policies]'
    this.propertyPolicyExamples = ' [Policy prefixes: BR, BN, CIM, TOP]'
    this.liabilityPolicyExamples = ' [Policy prefixes: GL, SL, OCP, XS, DO, PRD, RR]'
    this.confirmationNumber = this.eightHundredNumber
    this.confirmationEmail = this.contactEmail
    this.cssFile = "mcg-css.scss"
    this.sitePath = "/mcg/"
    this.reportingProcessContacted = "."
    this.confirmationContacted = "."

    return this
  }

}
