<template>
  <v-app id="app" data-cy="app-vue">
    <v-content>
      <ValidationSnackbar data-cy="app-validation-snackbar"></ValidationSnackbar>
      <Header data-cy="header-vue"/>
      <MobileStepper v-if="$vuetify.breakpoint.smAndDown"></MobileStepper>
      <router-view/>
      <Footer data-cy="footer-vue"/>
    </v-content>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import {Component} from "vue-property-decorator";
import Header from "@/views/components/Header.vue";
import Footer from "@/views/components/Footer.vue";
import MobileStepper from "@/views/components/MobileStepperComponent.vue";
import ValidationSnackbar from "@/views/components/ValidationSnackbar.vue";

@Component({
  components: {
    ValidationSnackbar,
    Header,
    Footer,
    MobileStepper
  }
})
export default class App extends Vue {
}
</script>
