export class Locus {
    addressLine1!: string;
    addressLine2!: string;
    city!: string;
    state!: string;
    stateCode!: string;
    postalCode!: string;
    country: string = 'United States';
    countryCode: string = 'US';
}
