<template>
  <v-dialog v-model="show" max-width="1000px" persistent data-cy="display-faq-dialog">
    <v-card style="overflow-x: hidden" data-cy="display-faq-card">
      <v-card-title>
        <span class="faq-label" data-cy="faq-label">Frequently Asked Questions</span>
        <span data-cy="faq-close-x" class="faq-close-x" @click="closeFaqModalOnClick">X</span>
      </v-card-title>
      <v-divider></v-divider>

      <v-row>
      <v-col class="faq-info" cols="12">
        <p class="mb-0" data-cy="intro">Below we provide some additional information about ClaimStartNow.</p>
      </v-col>
      </v-row>

      <v-row class="dialog-space">
        <v-col>
          <div data-cy="access-header-text" class="panel_header berninaSansCompressedFont">Access</div>
          <v-expansion-panels  accordion>
            <v-expansion-panel class="mobile-expansion-panel"  data-cy="access-expansion-panel">
              <v-expansion-panel-header data-cy="access-header">
                <div class="panel_description">How can I access ClaimStartNow?</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content data-cy="access-text" eager>
                <p>ClaimStartNow is available through the Agent Portal (agentportal.gaig.com) and the Insured Portal (MyGreatAmerican.gaig.com). Use the Submit a Claim button from either portal to get started. ClaimStartNow is also directly accessible through ClaimStartNow.gaig.com.</p>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="mobile-expansion-panel mt-5" data-cy="access-expansion-panel-2">
              <v-expansion-panel-header data-cy="when-access-header">
                <div class="panel_description">When I access ClaimStartNow using its direct link what login do I use?</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content data-cy="when-access-text" eager>
                <p>After clicking the “Login Here” button. You will enter the same username and password you use for the Agent Portal or the Insured Portal.</p>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="mobile-expansion-panel mt-5" data-cy="access-expansion-panel-3">
              <v-expansion-panel-header data-cy="how-ap-access-header">
                <div class="panel_description">How do I request access to the Agent Portal?</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content data-cy="how-ap-access-text" eager>
                <p>Go to agentportal.gaig.com. Where it says “Don’t have an account?”, click the Request Access button. Fill out the requested information and GAIG will work towards setting up your account.</p>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="mobile-expansion-panel mt-5" data-cy="access-expansion-panel-4">
              <v-expansion-panel-header data-cy="how-ip-access-header">
                <div class="panel_description">How do I get access to the Insured Portal?</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content data-cy="how-ip-access-text" eager>
                <p>Go to MyGreatAmerican.gaig.com Where it says “Don’t have an account? Click Request Access. You will need to register and enter your policy number.</p>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="mobile-expansion-panel mt-5" data-cy="access-expansion-panel-5">
              <v-expansion-panel-header data-cy="request-access-header">
                <div class="panel_description">Can I submit a claim without being logged in?</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content data-cy="request-access-text" eager>
                <p>Yes, complete the check box and then select “Start a Claim”.</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>


          <div data-cy="claim-header-text" class="mt-5 panel_header berninaSansCompressedFont">Entering a Claim</div>
          <v-expansion-panels accordion>
            <v-expansion-panel class="mobile-expansion-panel" data-cy="claim-expansion-panel">
              <v-expansion-panel-header data-cy="policy-unknown-header">
                <div class="panel_description">What do I do if I do not know my policy number?</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content data-cy="policy-unknown-text" eager>
                <p>After you enter the date of loss, select “No” for “Do you know the policy number?”. You will be able to proceed with entering your claim information.</p>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="mobile-expansion-panel mt-5" data-cy="claim-expansion-panel-2">
              <v-expansion-panel-header data-cy="policy-unavailable-header">
                <div class="panel_description">What do I do if the policy I want to select is not available in the dropdown?</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content data-cy="policy-unavailable-text" eager>
                <p>In the dropdown, select the “Manual Policy Entry” and enter the information. Providing this information helps you claims to be reviewed more effectively.</p>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="mobile-expansion-panel mt-5" data-cy="claim-expansion-panel-3">
              <v-expansion-panel-header data-cy="vehicle-unavailable-header">
                <div class="panel_description">What do I do if the vehicle I want to select is not in the vehicle search?</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content data-cy="vehicle-unavailable-text" eager>
                <p>You can manually type in the vehicle. You will only need to provide the information that you know like the VIN, Make, Model, or Year.</p>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="mobile-expansion-panel mt-5" data-cy="claim-expansion-panel-4">
              <v-expansion-panel-header data-cy="address-unavailable-header">
                <div class="panel_description">What do I do if the address I want to select is not in the address search?</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content data-cy="address-unavailable-text" eager>
                <p>You can manually type in the address. The State is required, but please fill out any other information you know at this time.</p>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="mobile-expansion-panel mt-5" data-cy="claim-expansion-panel-5">
              <v-expansion-panel-header data-cy="required-info-header">
                <div class="panel_description">What information is required?</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content data-cy="required-info-text" eager>
                <p>All required information will be denoted with a red asterisk <span style="color: red">*</span>. Providing more information helps GAIG resolve your claim more effectively, but we limit how much is required to submit so that you can have your claim set up quickly with the information you have available.</p>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="mobile-expansion-panel mt-5" data-cy="claim-expansion-panel-6">
              <v-expansion-panel-header data-cy="attachments-header">
                <div class="panel_description">Can I add attachments?</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content data-cy="attachments-text" eager>
                <p>Yes, you can add attachments on the Additional Information page. Most file types are accepted. File sizes cannot exceed 8MB each and 33MB total.</p>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="mobile-expansion-panel mt-5" data-cy="claim-expansion-panel-7">
              <v-expansion-panel-header data-cy="save-progress-header">
                <div class="panel_description">Can I save my progress to submit the claim later?</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content data-cy="save-progress-text" eager>
                <p>No, you cannot currently save and continue later. We’d appreciate your feedback on this to hear how it impacts your ability to use the app. Please send any feedback to ClaimStartNow@gaig.com.</p>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="mobile-expansion-panel mt-5" data-cy="claim-expansion-panel-8">
              <v-expansion-panel-header data-cy="confirmation-header">
                <div class="panel_description">Will I receive confirmation that my claim has been submitted?</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content data-cy="confirmation-text" eager>
                <p>Yes, we will send a confirmation email after the claim is submitted. We automatically include the person reporting the claim and the primary contact for the claim (when different). The Additional Information page also provides you with an option to copy additional email addresses on the confirmation email.</p>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="mobile-expansion-panel mt-5" data-cy="claim-expansion-panel-9">
              <v-expansion-panel-header data-cy="generate-claim-header">
                <div class="panel_description">Will my submission generate a Claim Number?</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content data-cy="generate-claim-text" eager>
                <p>In most cases a Claim Number will automatically be generated and included in the confirmation email you receive for reference. Depending on the information provided though, some submissions will need to be reviewed internally before a claim is set up in our system. In those cases, your confirmation email will still contain a Report Number that can be used for reference.</p>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="mobile-expansion-panel mt-5" data-cy="claim-expansion-panel-10">
              <v-expansion-panel-header data-cy="adjuster-header">
                <div class="panel_description">Will an adjuster be assigned to my claim immediately?</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content data-cy="adjuster-text" eager>
                <p>We are working towards this goal and submitting claims via ClaimStartNow is the first step towards GAIG being able to do that. For the time being, each claim will be reviewed by the next business day and a claim professional will be in contact with you.</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <button class="mr-5 mb-5 close-modal" data-cy="faq-close-button" type="button" @click="closeFaqModalOnClick">CLOSE</button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';

@Component
export default class Faq extends Vue {

  show = true

  closeFaqModalOnClick() {
    this.$emit('clicked', false)
    this.show = false
  }
}
</script>

<style lang="scss" scoped>

.dialog-space{
  padding: 0 1.5em;
}

.faq-label {
  display: flex;
  float: left;
  font-size: 40px;
  font-weight: bold;
  color: #00274e;
  font-family: jaf-bernina-sans-comp, sans-serif;
}

.faq-info {
  color: #00274e;
  font-size: 18px;
  font-family: proxima-nova, serif;
  padding: 20px 0 0 36px;
  font-weight: 400;
}

.faq-close-x {
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  flex: auto;
  text-align: right;
  white-space: nowrap;
}

.panel_header {
  color: #00274e;
  font-weight: bold;
  font-size: 20px;
  padding-bottom: 1%;
}

.panel_description {
  color: #00274e;
  font-size: 16px;
  font-family: proxima-nova, serif;
}

.close-modal {
  background-color: #00274e;
  color: white;
  text-align: center;
  font-weight: bold;
  padding: 10px 12px 12px 10px;
  border-radius: 4px;
}
.v-expansion-panel-header{
  background-color: #CCD4DC;
}

.v-expansion-panel-content{
  font-family: proxima-nova, sans-serif;
  color: #677d95;
  font-weight: 500;
}

.v-expansion-panel-content p{
  margin: 16px 0 0 0;
}

</style>
