export enum Suffixes {
    jr = "Jr.",
    sr = "Sr.",
    c_Ir = "I",
    c_II = "II",
    c_III = "III",
    c_IV = "IV",
    c_md = "M.D.",
    c_phd = "PhD.",
    c_do = "D.O.",
    c_dvm = "DVM"
}
