import {Locus} from "@/models/Locus";
export class Contact {
    fullName!: string;
    firstName!: string;
    lastName!: string;
    suffix!: string;
    suffixCode!: string;
    primaryPhone!: string;
    primaryPhoneExt!: string;
    cellPhone!: string;
    workPhone!: string;
    workPhoneExt!: string;
    primaryEmail!: string;
    location: Locus = new Locus();
}
