import {CommonDataUserType} from "@/models/CommonDataUserType";

export class User {
    firstName!: string;
    lastName!: string;
    emailAddress!: string;
    // phoneNumber!: string;

    producerCodes: Array<string> = [];
    roles!: Array<string>;

    isAuthenticated: Boolean = false;
    hasApplicationAccess!: Boolean;
    applicationAccessList!: Array<string>;
    userType!: CommonDataUserType;
}
