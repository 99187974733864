import {FnolSubmission} from "@/models/FnolSubmission";
import {FnolApi} from "@/services/backend-api";
import {InternalAdminData} from "@/models/InternalAdminData";
import {User} from "@/models/User";
import {PolicySearchRequest} from "@/models/PolicyData/PolicySearchRequest";
import {PolicySearchResponse} from "@/models/PolicyData/PolicySearchResponse";
import {PolicyRetrieveRequest} from "@/models/PolicyData/PolicyRetrieveRequest";
import {RiskInformation} from "@/models/PolicyData/RiskInformation";
import {FdwInquiryRequest} from "@/models/PolicyData/FdwInquiryRequest";
import {FdwInquiryResponse} from "@/models/PolicyData/FdwInquiryResponse";
import {PolicyData} from "@/models/PolicyData";
import {AplLoss} from "@/models/Loss/AplLoss";

export class FnolService {

  api = new FnolApi();

  async getKey(): Promise<string> {
    return await this.api.getKey();
  }

  async isTokenValid(token: string): Promise<boolean> {
    return await this.api.isTokenValid(token);
  }

  async getFnol(id: string): Promise<FnolSubmission> {
    if (null == id) {
      return new FnolSubmission();
    }
    return await this.api.getSubmission(id);
  }

  async saveFnol(fnol: FnolSubmission): Promise<FnolSubmission> {
    let data: FnolSubmission = new FnolSubmission();
    if (null == fnol.submissionId) {
      await this.api.createSubmission(fnol).then(value => {
        data = value;
      })
    } else {
      await this.api.updateSubmission(fnol).then(value => {
        data = value;
      })
    }
    return data;
  }

  async isInternal(): Promise<boolean> {
    return await this.api.isInternal();
  }

  async isEnvDevOrLocal(): Promise<boolean> {
    return await this.api.isEnvDevOrLocal();
  }

  async getInternalAdminData(): Promise<InternalAdminData> {
    return await this.api.getInternalAdminData();
  }

  async cancelFnol(fnol: FnolSubmission): Promise<FnolSubmission> {
    let data: FnolSubmission = new FnolSubmission();

      await this.api.cancelSubmission(fnol).then(value => {
        data = value;
      })

    return data;
  }

  async getUser(): Promise<User> {
    return await this.api.getUser();
  }

  async getAgentPolicies(request: FdwInquiryRequest): Promise<FdwInquiryResponse> {
    return await this.api.getAgentPolicies(request)
  }

  async getInsuredPolicies(email: string): Promise<PolicyData[]> {
    return await this.api.getInsuredPolicies(email)
  }

  async getBusinessUnit(request: FdwInquiryRequest): Promise<FdwInquiryResponse>{
    return await this.api.getBusinessUnit(request);
  }

  async doPolicySearch(request: PolicySearchRequest): Promise<PolicySearchResponse> {
    return await this.api.doPolicySearch(request);
  }

  async doPolicyRetrieve(request: PolicyRetrieveRequest): Promise<RiskInformation> {
    return await this.api.doPolicyRetrieve(request);
  }

  async getLossCauseList(aplLoss: AplLoss): Promise<Array<AplLoss>>{
    return await this.api.getLossCauseList(aplLoss)
  }

   getLossCauseDetailList(aplLoss: AplLoss): Promise<Array<AplLoss>> {
    return this.api.getLossCauseDetailList(aplLoss)
  }
}
