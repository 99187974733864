<template>
<div>
  <FaqMcg  v-if="this.$store.state.siteBrand.brandName === 'MCG'" data-cy="faq-mcg"/>
  <FaqApl  v-else data-cy="faq-apl"/>
</div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator'
import FaqApl from './FaqApl.vue';
import FaqMcg from './FaqMcg.vue'

@Component({
  components: {
      FaqApl,
      FaqMcg
  }
})
export default class FaqComponent extends Vue {

}

</script>
