import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from "@/store/store";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/home.vue'),
    alias: '/mcg'
  },
  {
    path: '/landing-page',
    name: 'landing-page',
    component: () => import(/* webpackChunkName: "landing-page" */ '../views/landing-page.vue'),
    alias: '/mcg/landing-page',
  },
  {
    path: '/submission-loader',
    name: 'submission-loader',
    component: () => import(/* webpackChunkName: "submission-loader" */ '../views/submission-loader.vue'),
    alias: '/mcg/submission-loader',
  },
  {
    path: '/getting-started',
    name: 'getting-started',
    component: () => import(/* webpackChunkName: "getting-started" */ '../views/getting-started.vue'),
    alias: '/mcg/getting-started'
  },
  {
    path: '/contact-information',
    name: 'contact-information',
    component: () => import(/* webpackChunkName: "contact-information" */ '../views/contact-information.vue'),
    alias: '/mcg/contact-information'
  },
  {
    path: '/loss-information',
    name: 'loss-information',
    component: () => import(/* webpackChunkName: "loss-information" */ '../views/loss-information.vue'),
    alias: '/mcg/loss-information'
  },
  {
    path: '/accident-information',
    name: 'accident-information',
    component: () => import(/* webpackChunkName: "accident-information" */ '../views/accident-information.vue'),
    alias: '/mcg/accident-information'
  },
  {
    path: '/property-loss-information',
    name: 'property-loss-information',
    component: () => import(/* webpackChunkName: "property-loss-information" */ '../views/property-loss-information.vue'),
    alias: '/mcg/property-loss-information'
  },
  {
    path: '/cargo-loss-information',
    name: 'cargo-loss-information',
    component: () => import(/* webpackChunkName: "cargo-loss-information" */ '../views/cargo-loss-information.vue'),
    alias: '/mcg/cargo-loss-information'
  },
  {
    path: '/liability-loss-information',
    name: 'liability-loss-information',
    component: () => import(/* webpackChunkName: "liability-loss-information" */ '../views/liability-loss-information.vue'),
    alias: '/mcg/liability-loss-information'
  },
  {
    path: '/other-party',
    name: 'other-party',
    component: () => import(/* webpackChunkName: "other-party" */ '../views/other-party.vue'),
    alias: '/mcg/other-party'
  },
  {
    path: '/additional-contacts',
    name: 'additional-contacts',
    component: () => import(/* webpackChunkName: "additional-contacts" */ '../views/additional-contacts.vue'),
    alias: '/mcg/additional-contacts'
  },
  {
    path: '/additional-information',
    name: 'additional-information',
    component: () => import(/* webpackChunkName: "additional-information" */ '../views/additional-information.vue'),
    alias: '/mcg/additional-information'
  },
  {
    path: '/review-and-submit',
    name: 'review-and-submit',
    component: () => import(/* webpackChunkName: "review-and-submit" */ '../views/review-and-submit.vue'),
    alias: '/mcg/review-and-submit'
  },
  {
    path: '/confirmation',
    name: 'confirmation',
    component: () => import(/* webpackChunkName: "confirmation" */ '../views/confirmation.vue'),
    alias: '/mcg/confirmation'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const canGo = store.state.canNavigate;
  const rootRoutes = store.state.siteBrand.rootRoutes;

  store.state.showBackButtonModal = false;

  if(rootRoutes.includes(to.path)){ next();  return; }

  if(canGo){
    store.state.canNavigate = false;
    next();
  }else{
    store.state.showBackButtonModal = true;
    next(false);
  }
})

export default router
