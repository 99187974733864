import {InjuredPerson} from "@/models/InjuredPerson";
import {DamagedPropertyInformation} from "@/models/DamagedPropertyInformation";

export class LiabilityLossInformation{
    injuredPersons: Array<InjuredPerson> = new Array<InjuredPerson>();
    incidentOccurOnInsuredProperty: boolean;
    incidentReportFiledWithInsured: boolean;
    vehiclesOrEquipmentInvolved: boolean;
    descriptionOfVehiclesAndEquipment: string;
    witnessOfIncident: boolean;
    policeFireEmtCalled: boolean;
    anyoneToHospital: boolean;
    injuredPersonsAmount: string = "";
    hasPropertyDamaged: boolean = false;
    damagedPropertyInformation: DamagedPropertyInformation = new DamagedPropertyInformation()
}
