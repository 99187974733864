import {GettingStarted} from "@/models/GettingStarted";
import {CustomerInformation} from "@/models/CustomerInformation";
import {LossInformation} from "@/models/LossInformation";
import {OtherParty} from "@/models/OtherParty";
import {AdditionalContacts} from "@/models/AdditionalContacts";
import {AdditionalInformation} from "@/models/AdditionalInformation";
import {Status} from "@/models/typelists/Status";
import {CommonData} from "@/models/CommonData";
import {PolicyDetails} from "@/models/PolicyDetails";

export class FnolSubmission {
  submissionId!: string;
  reportNumber!: string;
  status!: Status;
  message!: string;
  businessUnit: string = 'APL'
  isAuthenticated!: Boolean;

  commonData: CommonData = new CommonData()
  policyDetails: PolicyDetails = new PolicyDetails();
  gettingStarted: GettingStarted = new GettingStarted()
  customerInformation: CustomerInformation = new CustomerInformation()
  lossInformation: LossInformation  = new LossInformation()
  otherParty: OtherParty = null;
  additionalContacts: AdditionalContacts = new AdditionalContacts()
  additionalInformation: AdditionalInformation = new AdditionalInformation()
}
